
import { companies } from "@/api";
import { Actions, Mutations } from "@/store/models";
import Vue from "vue";
export default Vue.extend({
  name: "EmailVerification",
  data() {
    return {
      logo: require("/src/assets/gssi-emblem.svg"),
      image: require("/src/assets/LoginTempImage.jpg"),
      username: this.$route.query.email as string,
    };
  },
  computed: {},
  methods: {
    async resendEmail() {
      if (!this.username) {
        return;
      }
      try {
        await companies.nlc.signupUserResendEmail(this.username);
        this.$store.commit(Mutations.SetToast, {
          text: "Invite resent successfully.",
        });
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
  },
});
